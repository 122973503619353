(function () {
    'use strict';

    angular
        .module('aerosApp')
        .provider('storage', storage);

    storage.$inject = [];

    /* @ngInject */
    function storage() {
        var _prefix = 'ng_';
        /* jshint validthis:true */
        this.$get = Storage;
        this.setStoragePrefix = setStoragePrefix;

        Storage.$inject = ['$window'];

        /* @ngInject */
        function Storage($window) {
            var service = {
                set: set,
                get: get,
                remove: remove
            };

            function set(key, data) {
                $window.localStorage.setItem((_prefix + key).toUpperCase(), JSON.stringify(data));
            }

            function get(key) {
                return JSON.parse($window.localStorage.getItem((_prefix + key).toUpperCase()));
            }

            function remove(key) {
                $window.localStorage.removeItem((_prefix + key).toUpperCase());
            }

            return service;
        }

        function setStoragePrefix(prefix) {
            _prefix = prefix;
        }
    }
})();